@import "~@config/styles.less";

.HappyMonkeyPage {
  width: 100%;
  min-height: calc(100vh - 102px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HappyMonkeyPage .top-banner-div {
  width: 100vw;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HappyMonkeyPage .top-banner-div .container-top {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.HappyMonkeyPage .top-banner-div .top-banner {
  width: @small_screen_width;
  height: auto;
  @media screen and (max-width: @small_screen_width){ 
    width: 100%;
  }
  @media screen and (max-width: @mobile_width){
    width: calc(100vw - 30px);
  }
}
.HappyMonkeyPage .top-banner-div .container-top  .empty-div {
  flex: 1;
  background-image: linear-gradient(to bottom, #FA9C78, #FBD2A5); 
}
.HappyMonkeyPage .container {
  width: @small_screen_width;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 28px;
  // margin-top: 65px;
  @media screen and (max-width: @small_screen_width){ 
    width: 100%;
  }
  @media screen and (max-width: @mobile_width){
    width: 100%;
    padding: 0 15px;
  }
}
// .HappyMonkeyPage .container .top-banner {
//   width: 100%;
// }
.HappyMonkeyPage .container .headInfo {
  width: 100%;
  margin-top: 96px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: @mobile_width){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
.HappyMonkeyPage .container .headInfo .image {
  width: 38%;
  border-radius: 8px;
  @media screen and (max-width: @mobile_width){
    width: 100%;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: @mobile_width){
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .name {
  font-size: 40px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 50px;
  color: black;
  margin-top: 0;
  @media screen and (max-width: @mobile_width){
    font-size: 32px;
    line-height: 32px;
    margin-top: 0;
  }
}
.HappyMonkeyPage .container .color-line {
  width: 70%;
  height: 2px;
  background-color: rgba(#f89c66, 0.3);
  margin-top: 10px;
  position: relative;
  @media screen and (max-width: @mobile_width){
    width: 70%;
    margin-top: 15px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .color-line::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30%;
  background-color: #f89c66;
}
.HappyMonkeyPage .container .headInfo .infoContainer .remainingTxt {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 19px;
  color: @color_b3b3b3;
  margin-top: 32px;
  @media screen and (max-width: @mobile_width){
    margin-top: 15px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .accountList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}
.HappyMonkeyPage .container .headInfo .infoContainer .accountList .accountItem {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: @color_f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 400;
  color: @color_666666;
  @media screen and (max-width: @mobile_width){
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .priceTxt {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 19px;
  color: @color_b3b3b3;
  margin-top: 40px;
  @media screen and (max-width: @mobile_width){
    margin-top: 15px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .ethPrice {
  font-size: 40px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 49px;
  color: @color_ffc400;
  display: inline-block;
  @media screen and (max-width: @mobile_width){
    font-size: 32px;
    line-height: 32px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .ethPrice .ethPriceTxt {
  display: inline-block;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 19px;
  color: @color_666666;
  margin-left: 8px;
}
.HappyMonkeyPage .container .headInfo .infoContainer .moneyPrice {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 19px;
  color: @color_b3b3b3;
  margin-top: 8px;
  @media screen and (max-width: @mobile_width){
    font-size: 14px;
    line-height: 14px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .claimButn {
  height: 50px;
  border-radius: 8px;
  margin-top: 40px;
  // background-color: @color_ffc400;
  background-color: transparent;
  color: #6f3f3f;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  background-image: url("https://nft-1.oss-cn-beijing.aliyuncs.com/GachaCards/color-button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: @mobile_width){
    margin-top: 20px;
  }
}
.HappyMonkeyPage .container .headInfo .infoContainer .claimButn:hover {
  background-color: @color_ffaa00;
}
.HappyMonkeyPage .container .headInfo .infoContainer .download-button {
  height: 48px;
  margin-top: 16px;
  cursor: pointer;
}
.HappyMonkeyPage .container .content {
  width: 100%;
  margin-top: 52px;
  position: relative;
}
.HappyMonkeyPage .container .content .tabs {
  width: 100%;
  height: 104px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  @media screen and (max-width: @mobile_width){
    height: 80px;
  }
}
.HappyMonkeyPage .container .content .tab-color-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(#f89c66, 0.3);
  @media screen and (max-width: @mobile_width){
    display: none;
  }
}
.HappyMonkeyPage .container .content .tab-color-line::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10%;
  background-color: #f89c66;
}
.HappyMonkeyPage .container .content .tabContainer {
  width: 100%;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin-top: 144px;
  @media screen and (max-width: @mobile_width){
    margin-top: 80px;
    max-height: calc(100vh - 144px);
  }
}
.HappyMonkeyPage .container .content .tabContainer::-webkit-scrollbar{
  display: none;
}
.HappyMonkeyPage .container .content .tabContainer .cardsList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  @media screen and (max-width: @mobile_width){
    justify-content: space-between;
    margin-top: 10px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem {
  width: 280px;
  margin-right: 24px;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid @color_e6e6e6;
  padding-bottom: 24px;
  @media screen and (max-width: @mobile_width){
    width: calc(50% - 5px);
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem .itemImage {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem .itemName {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 16px;
  color: black;
  margin: 16px 0 0 24px;
  @media screen and (max-width: @mobile_width){
    font-size: 14px;
    margin: 15px 0 0 15px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem .itemDescContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 24px 0 24px;
  @media screen and (max-width: @mobile_width){
    margin: 15px 15px 0 15px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem .itemDescContainer .item_type {
  width: 24px;
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem .itemDescContainer .descKey {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 14px;
  color: @color_999999;
}
.HappyMonkeyPage .container .content .tabContainer .cardsList .cardItem .itemDescContainer .descValue {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 14px;
  color: @color_666666;
}
.HappyMonkeyPage .container .content .tabContainer .seriesListTxt {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  color: @color_b3b3b3;
  margin-top: 10px;
}
.HappyMonkeyPage .container .content .tabContainer .backgroundTitle {
  font-size: 40px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 50px;
  color: black;
  margin-top: 56px;
  @media screen and (max-width: @mobile_width){
    width: 100%;
    margin-top: 40px;
    font-size: 32px;
    line-height: 40px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .backgroundContent {
  margin-top: 40px;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  color: @color_666666;
  @media screen and (max-width: @mobile_width){
    margin-top: 20px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .introduction-image {
  margin-top: 40px;
  width: 100%;
}
.HappyMonkeyPage .container .content .tabContainer .background_image {
  width: 100%;
  height: 300px;
  background: black;
  border-radius: 16px;
  margin-top: 32px;
  @media screen and (max-width: @mobile_width){
    margin-top: 20px;
  }
}
.HappyMonkeyPage .container .content .tabContainer .buttonContainer {
  width: 100%;
  margin: 120px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: @mobile_width){
    margin: 50px 0;
  }
}
.HappyMonkeyPage .container .content .tabContainer .buttonContainer .homeButn {
  width: 303px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid black;
  color: black;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HappyMonkeyPage .container .content .tabContainer .buttonContainer .homeButn .arrowLeft {
  border: 1px solid black;
  border-width: 2px 0 0 2px;
  width: 8px;
  height: 8px;
  line-height: 0;
  font-size: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-right: -7px;
}
.HappyMonkeyPage .container .content .tabContainer .buttonContainer .homeButn .arrowLine {
  height: 2px;
  width: 32px;
  margin-right: 12px;
  background-color: black;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.HappyMonkeyPage .container .content .tabContainer .buttonContainer .homeButn:hover .arrowLine{
  width: 50px;
}