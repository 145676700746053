@import "~@config/styles.less";

.tabList {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: @mobile_width){
    justify-content: center;
  }
}
.tabList .tabItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 48px;
  cursor: pointer;

  @media screen and (max-width: @mobile_width){
    width: 50%;
    justify-content: center;
    margin: 0;
  }
}
.tabList .tabItemActive {
  background-image: url("https://nft-1.oss-cn-beijing.aliyuncs.com/GachaCards/collection-color.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 20px;
  border-radius: 8px;
}
.tabList .tabItem .tabName {
  font-size: 28px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 60px;
  color: @color_b3b3b3;
  @media screen and (max-width: @mobile_width){
    font-size: 14px;
    line-height: 40px;
  }
}
.tabList .tabItem .tabNameActive {
  color: black;
} 
.tabList .tabItem .tabLine {
  width: 72px;
  height: 4px;
  border-radius: 2px;
  background-color: @color_ffc400;
}