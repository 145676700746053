@import "~@config/styles.less";

.homePage {
  width: 100%;
  min-height: calc(100vh - 102px);
  display: flex;
  flex-direction: column;
  align-items: center;  
}
.homePage .container {
  width: @small_screen_width;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 28px;
  margin-top: 65px;
  @media screen and (max-width: @small_screen_width){ 
    width: 100%;
  }
  @media screen and (max-width: @mobile_width){ 
    width: 100%;
    padding: 0 15px;
  }
}
.homePage .container .headSlogan {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 120px;
  @media screen and (max-width: @mobile_width){ 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
}
.homePage .container .headSlogan .desc_container {
  width: calc(50% - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: @mobile_width){ 
    width: calc(100% - 30px);
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
.homePage .container .headSlogan .desc_container .title {
  font-size: 80px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 96px;
  color: black;
  @media screen and (max-width: @mobile_width){ 
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
}
.homePage .container .headSlogan .desc_container .content {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 400;
  color: @color_666666;
  margin-top: 38px;
  @media screen and (max-width: @mobile_width){
    font-size: 15px;
    margin-top: 20px;
    text-align: center;
  }
}
.homePage .container .headSlogan .desc_container .claimButn {
  width: 277px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  background-color: @color_ffc400;
  color: white;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: @mobile_width){
    margin-top: 20px;
  }
}
.homePage .container .headSlogan .desc_container .claimButn .arrowLine {
  height: 2px;
  width: 32px;
  background-color: white;
  margin-left: 12px;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.homePage .container .headSlogan .desc_container .claimButn .arrowRight {
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  width: 8px;
  height: 8px;
  line-height: 0;
  font-size: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-left: -7px;
}
.homePage .container .headSlogan .desc_container .claimButn:hover {
  background-color: @color_ffaa00;
}
.homePage .container .headSlogan .desc_container .claimButn:hover .arrowLine{
  width: 50px;
}
.homePage .container .headSlogan .image {
  width: calc(50% - 65px);
  margin-left: 130px;
  @media screen and (max-width: @mobile_width){
    width: calc(100% - 30px);
    margin-left: 0;
  }
}
.homePage .container .newsEvents {
  margin-top: 138px;
  width: 100%;
  @media screen and (max-width: @mobile_width){
    text-align: center;
    margin-top: 50px;
  }
}
.homePage .container .newsEvents .title {
  font-size: 28px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 34px;
  color: black;
}
.homePage .container .newsEvents .carouselList {
  width: 100%;
  margin-top: 34px;
}
.home_dots_style {
  height: 30px;
}
.homePage .container .newsEvents .carouselList .itemContainer {
  width: 100%;
}
.homePage .container .catalog {
  margin-top: 124px;
  width: 100%;
  @media screen and (max-width: @mobile_width){
    margin-top: 50px;
    text-align: center;
  }
}
.homePage .container .catalog .title {
  font-size: 28px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 34px;
  color: black;
}
.homePage .container .catalog .list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 100px;
  @media screen and (max-width: @mobile_width){
    margin-bottom: 50px;
  }
}
.homePage .container .catalog .list .listItem {
  width: calc(50% - 12px);
  margin-bottom: 24px;
  position: relative;
  @media screen and (max-width: @mobile_width){
    width: 100%;
  }
}
.homePage .container .catalog .list .listItem .image {
  width: 100%;
}
.homePage .container .catalog .list .listItem .itemContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}
.homePage .container .catalog .list .listItem:last-child .itemContainer {
  cursor: not-allowed;
}
.homePage .container .catalog .list .listItem .itemContainer .name {
  font-size: 32px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 39px;
  color: white;
  margin-left: 32px;
  @media screen and (max-width: @mobile_width){
    font-size: 24px;
    margin-left: 15px;
  }
}
.homePage .container .catalog .list .listItem .itemContainer .content {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 24px;
  color: white;
  margin-top: 12px;
  margin-left: 32px;
  @media screen and (max-width: @mobile_width){
    font-size: 16px;
    margin-left: 15px;
    margin-top: 0;
  }
}
.homePage .container .catalog .list .listItem .itemContainer .enterButn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  margin-top: 24px;
  @media screen and (max-width: @mobile_width){
    margin-left: 15px;
    margin-top: 10px;
  }
}
.homePage .container .catalog .list .listItem .itemContainer .enterButn .arrowLine {
  height: 2px;
  width: 88px;
  background-color: white;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.homePage .container .catalog .list .listItem .itemContainer .enterButn .arrowRight {
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  width: 10px;
  height: 10px;
  line-height: 0;
  font-size: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-left: -10px;
}
.homePage .container .catalog .list .listItem .itemContainer:hover .enterButn .arrowLine {
  width: 100px;
}
.homePage .ant-carousel {
  .slick-dots-bottom {
    bottom: -24px;
    height: 12px;
  }
  .slick-dots li {
    width: 12px !important;
    height: 12px;
    border-radius: 6px;

    button {
      width: 12px !important;
      height: 12px;
      border-radius: 6px;
      background-color: @color_edf3f8;
    }
  }
  .slick-dots li.slick-active {
    button{
      background-color: @color_ffc400
    }
  }
}
.homePage .onlyVisibleInMobile {
  display: none;
  @media screen and (max-width: @mobile_width){
    display: block;
  }
}
.homePage .onlyNoneInMobile {
  display: block;
  @media screen and (max-width: @mobile_width){
    display: none;
  }
}