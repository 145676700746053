@import "~@config/styles.less";

.buyModal .ant-modal-content {
  background-color: white;
  border-radius: 8px;
  width: 560px;
  @media screen and (max-width: @mobile_width){
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}
.buyModal .ant-modal-content .ant-modal-close {
  color: black;
  font-size: 20px;
}
.buyModal .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  @media screen and (max-width: @mobile_width){
    padding: 32px 15px;
  }
}
.buyModal .ant-modal-content .ant-modal-body .boxImageContainer {
  margin-top: 20px;
  width: 300px;
  height: 350px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: @mobile_width){
    width: 200px;
    height: 250px;
  }
}
.buyModal .ant-modal-content .ant-modal-body .boxImageContainer .boxImage {
  width: 300px;
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  @media screen and (max-width: @mobile_width){
    width: 200px;
  }
}
.buyModal .ant-modal-content .ant-modal-body .boxImageShadeAnimation {
  animation-name: shakeBoxImage;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.buyModal .ant-modal-content .ant-modal-body .boxImage_narrow_animation {
  animation-name: narrowBoxImage;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.buyModal .ant-modal-content .ant-modal-body .boxImageEnlargeAnimation {
  animation-name: enlargeBoxImage;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
@keyframes shakeBoxImage {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    bottom: 50px;
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-10deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(10deg);
  }
  90% {
    bottom: 50px;
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes narrowBoxImage {
  0% {
    bottom: 50px;
  }
  100% {
    bottom: 0;
    width: 0;
  }
}
@keyframes enlargeBoxImage {
  0% {
    width: 0;
  }
  100% {
    bottom: 0;
    width: 300px;
  }
}
.buyModal .ant-modal-content .ant-modal-body .boxImageContainer .box_shade {
  width: 50px;
  height: 50px;
}
.buyModal .ant-modal-content .ant-modal-body .price {
  font-size: 40px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 50px;
  color: @color_ffc400;
  margin: 34px 0;
  @media screen and (max-width: @mobile_width){
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0;
  }
}
.buyModal .ant-modal-content .ant-modal-body .claimButn {
  height: 50px;
  border-radius: 25px;
  background-color: @color_ffc400;
  color: white;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  @media screen and (max-width: @mobile_width){
    height: 40px;
    border-radius: 20px;
  }
}
.buyModal .ant-modal-content .ant-modal-body .claimButn:hover {
  background-color: @color_ffaa00;
}
.buyModal .ant-modal-content .ant-modal-body .waitTxt {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 29px;
  color: black;
  margin-top: 85px;
  margin-bottom: 53px;
  @media screen and (max-width: @mobile_width){
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 8px;
    text-align: center;
  }
}
.buyModal .ant-modal-content .ant-modal-body .openTitle {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 29px;
  color: black;
  margin: 40px 0;
  @media screen and (max-width: @mobile_width){
    font-size: 18px;
    margin: 20px;
  }
}
.buyModal .drawCardContainer {
  // width: 272px;
  width: 400px;
  position: relative;
  margin-top: 48px;
  min-height: 272px;
  @media screen and (max-width: @mobile_width){
    margin-top: 32px;
    transform: scale(0.8);
  }
}
.buyModal .drawCardContainer .image {
  width: 100%;
}
.buyModal .drawCardContainer .backgroundColor {
  position: absolute;
  // top: 32px;
  top: 139px;
  // left: 20px;
  left: 110px;
  // right: 20px;
  right: 100px;
  height: 132px;
  // background-image: linear-gradient(@color_4d4d4d, @color_1a1a1a);
  border-radius: 4px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buyModal .drawCardContainer .backgroundColor .cardListContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
}
.buyModal .drawCardContainer .backgroundColor .cardListContainer .cardList {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
}
.buyModal .drawCardContainer .backgroundColor .cardListContainer .cardList .cardItem {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  margin-right: 16px;
}
.buyModal .drawCardContainer .backgroundColor .cardListContainer .cardList .cardItem .cardImage {
  width: 100%;
  border-radius: 8px;
  background-color: @color_333333;
  border: 1px solid black;
  box-sizing: border-box;
}
.buyModal .drawCardContainer .backgroundColor .cardListContainer .drawingCardAnimation {
  animation-name: drawingCardKeyFrames;
  animation-duration: 2.0s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes drawingCardKeyFrames {
  0% {
    left: 0;
  }
  100% {
    left: -928px;
  }
}
.buyModal .drawCardContainer .backgroundColor .cardListContainer .drawedCardAnimation {
  animation-name: drawedCardKeyFrames;
  animation-duration: 3.0s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
}
@keyframes drawedCardKeyFrames {
  0% {
    left: 0;
  }
  50% {
    left: -928px;
  }
  100% {
    left: -166px;
  }
}