@import "~@config/styles.less";

.head {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 999;
}
.head .container {
  width: @small_screen_width;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: @small_screen_width){ 
    width: 100%;
  }
}
.head .container .title {
  font-size: 24px;
  font-family: Montserrat;
  color: @color_ffc400;
  font-weight: 600;
  margin-left: 28px;
  cursor: pointer;
  @media screen and (max-width: @mobile_width){
    margin-left: 15px;
    font-size: 18px;
  }
}
.head .container .containerR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 28px;
  @media screen and (max-width: @mobile_width){
    margin-right: 15px;
  }
}
.head .container .containerR .connectButn {
  width: 186px;
  height: 36px;
  border-radius: 18px;
  background-color: @color_ffc400;
  color: white;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  @media screen and (max-width: @mobile_width){
    width: 140px;
    font-size: 14px;
    height: 30px;
  }
}
.head .container .containerR .connectButn:hover {
  background-color: @color_ffaa00;
}
.head .container .containerR .connected {
  width: 186px;
  height: 36px;
  border-radius: 18px;
  background-color: @color_f5f5f5;
  color: @color_666666;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  cursor: pointer;
  @media screen and (max-width: @mobile_width){
    width: 140px;
    font-size: 14px;
    height: 30px;
  }
}
.head .container .containerR .connected::after {
  display: none;
}
.head .container .containerR .networkError {
  width: 186px;
  height: 36px;
  border-radius: 18px;
  background-color: @color_ffcccc;
  color: red;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  cursor: default;
  @media screen and (max-width: @mobile_width){
    width: 140px;
    font-size: 14px;
    height: 30px;
  }
}
.head .container .containerR .networkError::after {
  display: none;
}
.head .container .containerR .profileButn {
  width: 36px;
  margin-left: 24px;
  // cursor: pointer;
  @media screen and (max-width: @mobile_width){
    width: 30px;
    margin-left: 15px;
  }
}

