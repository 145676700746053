@import "~@config/styles.less";

.home-2-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  position: relative;

  .image {
    width: 100%;
  }
  .pc-image {
    display: block;
    @media screen and (max-width: @mobile_width){
      display: none;
    }
  }
  .mobile-image {
    display: none;
    @media screen and (max-width: @mobile_width){
      display: block;
    }
  }
  .change-button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99;
    background-image: linear-gradient(to bottom, #FA9C78, #FBD2A5);
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  .push-button {
    position: absolute;
    left: 0;
    top: 70%;
    width: 100%;
    height: 30%;
    z-index: 9;
    cursor: pointer;
  }
}