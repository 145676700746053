@import "~@config/styles.less";

.foot {
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.foot .container {
  width: @small_screen_width;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: @small_screen_width){ 
    width: 100%;
  }
  @media screen and (max-width: @mobile_width){
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.foot .container .menuList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 28px;
  @media screen and (max-width: @mobile_width){
    margin-left: 0;
    justify-content: center;
  }
}
.foot .container .menuList .menuItem {
  font-size: 18px;
  font-family: Montserrat;
  color: @color_cccccc;
  font-weight: 400;
  margin-right: 32px;
  cursor: pointer;
  @media screen and (max-width: @mobile_width){
    font-size: 14px;
    margin: 0 16px;
  }
}
.foot .container .inc {
  font-size: 18px;
  font-family: Montserrat;
  color: @color_cccccc;
  font-weight: 400;
  margin-right: 28px;
  @media screen and (max-width: @mobile_width){
    margin-right: 0;
    font-size: 14px;
    margin-top: 10px;
  }
}