@import "~@config/styles.less";

.profilePage {
  width: 100%;
  min-height: calc(100vh - 102px);
  display: flex;
  flex-direction: column;
  align-items: center;  
}
.profilePage .bannerContainer {
  width: 100%;
  height: 240px;
  margin-top: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profilePage .bannerContainer .infoContainer {
  position: absolute;
  bottom: -105px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profilePage .bannerContainer .infoContainer .icon {
  width: 116px;
  height: 116px;
  border-radius: 58px;
  background-color: white;
}
.profilePage .bannerContainer .infoContainer .address {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  color: black;
  margin-top: 26px;
}
.profilePage .container {
  width: @small_screen_width;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 28px;
  margin-top: 150px;
  @media screen and (max-width: @small_screen_width){ 
    width: 100%;
  }
  @media screen and (max-width: @mobile_width){
    width: 100%;
    padding: 0 15px;
  }
}
.profilePage .container .sectionTitle {
  font-size: 28px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 60px;
  color: black;
  @media screen and (max-width: @mobile_width){
    font-size: 18px;
    line-height: 40px;
  }
}
.profilePage .container .cardList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-top: 40px;
  margin-bottom: 100px;
  @media screen and (max-width: @mobile_width){
    justify-content: space-between;
    margin-top: 20px;
  }
}
.profilePage .container .cardList .cardItem {
  width: 280px;
  margin-right: 24px;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid @color_e6e6e6;
  @media screen and (max-width: @mobile_width){
    width: calc(50% - 5px);
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.profilePage .container .cardList .cardItem .itemImage {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.profilePage .container .cardList .cardItem .itemName {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 16px;
  color: black;
  margin: 24px;
  @media screen and (max-width: @mobile_width){
    font-size: 14px;
    margin: 15px;
  }
}
.profilePage .container .emptyContainer {
  margin: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: @mobile_width){
    margin: 30px 0;
  }
}
.profilePage .container .emptyContainer .image {
  width: 96px;
}
.profilePage .container .emptyContainer .txt {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 29px;
  color: black;
  margin-top: 12px;
  @media screen and (max-width: @mobile_width){
    font-size: 18px;
  }
}
.profilePage .container .emptyContainer .homeButn {
  width: 285px;
  height: 50px;
  border-radius: 25px;
  margin-top: 40px;
  background-color: @color_ffc400;
  color: white;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  box-shadow: none;
  text-shadow: none;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profilePage .container .emptyContainer .homeButn .arrowLine {
  height: 2px;
  width: 32px;
  background-color: white;
  margin-left: 12px;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.profilePage .container .emptyContainer .homeButn .arrowRight {
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  width: 8px;
  height: 8px;
  line-height: 0;
  font-size: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-left: -7px;
}
.profilePage .container .emptyContainer .homeButn:hover {
  background-color: @color_ffaa00;
}
.profilePage .container .emptyContainer .homeButn:hover .arrowLine{
  width: 50px;
}